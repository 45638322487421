<template>
  <div class="mb2 title center color-white text-shadow">
    {{ $t('funIceBreaker.story.title.0') }}
    <br />
    {{
      $t('funIceBreaker.story.title.1', {
        name: game.players[game.storyPlayerIndex].name
      })
    }}
  </div>
  <FunIceBreakerBox class="flex flex-column justify-between items-center">
    <div v-if="currentPlayer" class="mb2 bold">
      {{
        $t('funIceBreaker.story.youGetPoints', {
          value: currentPlayer.pointsInPreviousRound
        })
      }}
    </div>
    <div
      v-for="(option, index) in myMatching.matching"
      :key="index"
      class="flex items-center matching-item"
    >
      <div class="flex items-center name-nickname">
        <BaseTooltipWrapper :tooltip="option.player?.name">
          <div class="name">{{ option.player?.name }}</div>
        </BaseTooltipWrapper>
      </div>
      <div class="flex flex-column items-center justify-center match-arrow-wrapper">
        <!--        <div class="invalid-answer flex items-center" v-if="!option.isMatch">-->
        <!--          <div class="mr1">{{ $t('funIceBreaker.story.shouldBe') }}</div>-->
        <!--          <BaseTooltipWrapper :tooltip="option.validAnswer">-->
        <!--            <b>{{ option.validAnswer }}</b>-->
        <!--          </BaseTooltipWrapper>-->
        <!--        </div>-->
        <img
          class="match-arrow"
          :src="require(`@/assets/images/arrow-answer-${option.isMatch ? 'valid' : 'invalid'}.svg`)"
          alt=""
        />
      </div>
      <BaseTooltipWrapper :tooltip="option.answer">
        <div class="answer">{{ option.answer }}</div>
      </BaseTooltipWrapper>
    </div>
    <div class="all-answers mt2">
      <div
        v-for="option in game.activeGuessingOptions"
        class="flex all-answers-item"
        :key="option.player.name"
      >
        <span v-if="game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE">
          <b>{{ option.player.name }}</b>
          {{ $t('funIceBreaker.story.answered') }} "{{ option.answer }}"
        </span>
        <span v-else>
          <b>{{ option.player.name }}</b>
          {{ $t('funIceBreaker.story.lied') }} "{{ option.answer[2] }}"
        </span>
      </div>
    </div>
  </FunIceBreakerBox>
  <div class="mt3">
    <Button v-if="currentPlayer?.admin && game.noOfQuestionsLeft > 1" @click="startNextRound">
      {{ $t('funIceBreaker.story.startNextRound') }}
    </Button>
    <Button v-if="currentPlayer?.admin && game.noOfQuestionsLeft === 1" @click="endGame">
      {{ $t('funIceBreaker.story.endGame') }}
    </Button>
    <div v-if="!currentPlayer?.admin" class="color-white text-shadow">
      {{ $t('funIceBreaker.story.adminStartsNextRound') }}
    </div>
  </div>
</template>

<script>
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper.vue';
  import FunIceBreakerBox from '@/components/funicebreaker/FunIceBreakerBox';
  import useFirebaseFunIceBreaker from '@/composables/use-firebase-funicebreaker';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { FUN_ICE_BREAKER_QUESTION_TYPES } from '@/interfaces/funicebreaker';

  export default {
    name: 'FunIceBreakerPhaseStory',
    components: { FunIceBreakerBox, BaseTooltipWrapper },
    props: {
      game: {
        type: Object,
        default: () => ({})
      },
      user: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      myMatching() {
        return this.game.playersMatching.find(matching => matching.player.id === this.user.uid);
      }
    },
    setup(props) {
      const { currentPlayer, startNextRound, endGame } = useFirebaseFunIceBreaker();
      const store = useStore();
      const { t, locale } = useI18n();

      const speak = message => {
        const msg = new SpeechSynthesisUtterance(message);
        msg.lang = locale.value === 'en' ? 'en-GB' : 'pl-PL';

        window.speechSynthesis.speak(msg);
      };

      setTimeout(() => {
        if (store.state.soundOn) {
          speak(t('funIceBreaker.story.title.0'));
          speak(
            t('funIceBreaker.story.title.1', {
              name: props.game.players[props.game.storyPlayerIndex].name
            })
          );
        }
      }, 400);

      return {
        currentPlayer,
        startNextRound,
        endGame,
        FUN_ICE_BREAKER_QUESTION_TYPES
      };
    }
  };
</script>

<style scoped lang="scss">
  .title {
    @include font(24px, 600);
  }

  .matching-item {
    padding: 8px 0;
    height: global-vars(ui-default-measure5x);
    border-bottom: 1px solid color(gray-background);

    @include mobile() {
      align-items: center;

      .match-arrow {
        display: none;
      }
    }
  }

  .name {
    width: 120px;
    @include font(15px, 600, 28px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include xs-down() {
      width: 90px;
    }
  }

  .nickname {
    margin-left: global-vars(ui-default-measure);
    @include font(12px, 600, 18px);
  }

  .name-nickname {
    width: 120px;
  }

  .answer {
    width: 200px;
    text-align: end;
    @include font(15px, 500, 28px);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include xs-down() {
      width: 100px;
    }
  }

  .invalid-answer {
    @include font(12px, 500, 14px);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
  }

  .match-arrow {
    margin: 0 32px;
    width: 150px;

    @include mobile() {
      width: 80px;
    }

    &-wrapper {
      flex: 1;
    }
  }

  .all-answers {
    width: 100%;
    padding: global-vars(ui-default-measure);
    border-radius: global-vars(ui-default-measure);
    background: #fafafa;
    border: 1px solid #eee;
  }

  .all-answers-item {
    @include font(12px, 500, 18px);
    border-bottom: 1px solid #eee;
    padding-bottom: 2px;
    margin-bottom: 2px;

    @include mobile() {
      @include font(11px, 500, 14px);
    }
  }
</style>
