<template>
  <div class="funicebreaker__box">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'FunIceBreakerBox'
  };
</script>

<style scoped lang="scss">
  .funicebreaker__box {
    padding: 24px 50px;
    background: white;
    border-radius: global-vars(ui-default-measure3x);
    box-shadow: global-vars(ui-default-box-shadow--light);
    max-width: 700px;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;

    @include lg-up() {
      padding: 30px 60px;
    }

    @include mobile() {
      max-height: 50vh;
      width: 100%;
      padding: 16px 20px;
    }
  }
</style>
