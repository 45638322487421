
  import { defineComponent, onMounted, ref, Ref, computed, watch } from 'vue';

  import FunIceBreakerJoinGame from '@/components/funicebreaker/FunIceBreakerJoinGame.vue';
  import FunIceBreakerLeftNavigation from '@/components/funicebreaker/FunIceBreakerLeftNavigation.vue';
  import FunIceBreakerTopNav from '@/components/funicebreaker/FunIceBreakerTopNav.vue';
  import PhaseWelcome from '@/components/common/PhaseWelcome.vue';
  import FunIceBreakerPhaseQuestion from '@/components/funicebreaker/FunIceBreakerPhaseQuestion.vue';
  import FunIceBreakerPhaseMatching from '@/components/funicebreaker/FunIceBreakerPhaseMatching.vue';
  import FunIceBreakerPhaseStory from '@/components/funicebreaker/FunIceBreakerPhaseStory.vue';
  import FunIceBreakerInstructionGuy from '@/components/funicebreaker/FunIceBreakerInstructionGuy.vue';
  import FunIceBreakerGameEnded from '@/components/funicebreaker/FunIceBreakerGameEnded.vue';

  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import IconAttribute from '@/components/common/IconAttribute.vue';
  import LogoSpinner from '@/components/common/LogoSpinner.vue';
  import SoundMuting from '@/components/SoundMuting.vue';
  import AddAdmin from '@/components/AddAdmin.vue';
  import GameLogout from '@/components/GameLogout.vue';

  import useFirebaseFunIceBreaker from '@/composables/use-firebase-funicebreaker';
  import { useRouter } from 'vue-router';
  import useToast from '@/composables/use-toast';
  import { FUN_ICE_BREAKER_PHASES } from '@/helpers/funicebreaker/initials';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import useFirebase from '@/composables/use-firebase';

  export default defineComponent({
    name: 'FunIceBreakerGame',
    components: {
      GameLogout,
      IconAttribute,
      AddAdmin,
      SoundMuting,
      LanguageSelection,
      FunIceBreakerJoinGame,
      FunIceBreakerLeftNavigation,
      FunIceBreakerTopNav,
      FunIceBreakerInstructionGuy,
      FunIceBreakerGameEnded,
      LogoSpinner
    },
    data() {
      return {
        optionsVisible: false
      };
    },
    setup() {
      const gameLoading: Ref = ref(false);
      const joinLoading: Ref = ref(false);

      const { currentRoute } = useRouter();
      const store = useStore();
      const { successToast } = useToast();
      const { t, locale } = useI18n();
      const { getCurrentUser } = useFirebase();
      const user = getCurrentUser();

      const {
        getCurrentCompany,
        getOneFunIceBreakerGameAndListen,
        playerHasJoinedTheGame,
        showJoinGame,
        joinGame,
        showStartGameButton,
        startGame,
        cancelGame,
        currentPlayer,
        addAdmin
      } = useFirebaseFunIceBreaker();

      const instructions = computed(() => [
        t('funIceBreaker.shortInstructions.0'),
        t('funIceBreaker.shortInstructions.1'),
        t('funIceBreaker.shortInstructions.2'),
        t('funIceBreaker.shortInstructions.3')
      ]);

      const funIceBreakerPhase = computed(() => {
        switch (store.state.currentFunIceBreakerGame.phase) {
          case 'WELCOME':
            return PhaseWelcome;
          case FUN_ICE_BREAKER_PHASES.QUESTION.value:
            return FunIceBreakerPhaseQuestion;
          case FUN_ICE_BREAKER_PHASES.MATCHING.value:
            return FunIceBreakerPhaseMatching;
          case FUN_ICE_BREAKER_PHASES.STORY.value:
            return FunIceBreakerPhaseStory;
          default:
            return PhaseWelcome;
        }
      });

      const condition = computed(() => {
        switch (store.state.currentFunIceBreakerGame.phase) {
          case 'WELCOME':
          case FUN_ICE_BREAKER_PHASES.STORY.value:
          case FUN_ICE_BREAKER_PHASES.QUESTION.value:
            return true;
          case FUN_ICE_BREAKER_PHASES.MATCHING.value:
            return store.state.currentFunIceBreakerGame.activeGuessingOptions.length;
          default:
            return true;
        }
      });

      const currentGame = computed(() => {
        return store.state.currentFunIceBreakerGame;
      });

      onMounted(async () => {
        gameLoading.value = true;

        await getCurrentCompany(currentRoute.value.params.companyId);

        // @ts-ignore
        await getOneFunIceBreakerGameAndListen(String(currentRoute.value.params.gameId));

        locale.value = store.state.company.signupLanguage || 'en';
        gameLoading.value = false;
      });

      const copy = (): void => {
        // @ts-ignore
        navigator.clipboard.writeText(
          `https://${window.location.hostname}/funicebreaker/${currentRoute.value.params.companyId}/${currentRoute.value.params.gameId}`
        );
        successToast(t('common.copiedToClipboard'));
      };

      const handleAddAdmin = (id: string) => {
        addAdmin(id);
        successToast(t('common.adminChanged'));
      };

      const handleJoinGame = async (data: any) => {
        joinLoading.value = true;
        await joinGame(data);
        joinLoading.value = false;
      };

      watch(
        () => currentGame.value.noOfQuestionsLeft,
        newVal => {
          if (newVal === 1) {
            if (store.state.soundOn) {
              const msg = new SpeechSynthesisUtterance(t('common.lastRound'));
              msg.lang = locale.value === 'pl' ? 'pl-PL' : 'en-GB';

              window.speechSynthesis.speak(msg);
            }
          }
        }
      );

      return {
        currentGame,
        showJoinGame,
        gameLoading,
        showStartGameButton,
        startGame,
        handleJoinGame,
        cancelGame,
        copy,
        playerHasJoinedTheGame,
        funIceBreakerPhase,
        FUN_ICE_BREAKER_PHASES,
        condition,
        user,
        instructions,
        currentPlayer,
        joinLoading,
        handleAddAdmin
      };
    }
  });
