<template>
  <div v-if="show" class="funicebreaker-matching-truthlie-tutorial ml1">
    <div v-for="(item, index) in items" class="flex items-center" :key="index">
      <div
        v-for="answer in item"
        :key="answer"
        class="answer"
        :class="selected.includes(answer) && 'selected'"
      >
        {{ answer }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FunIceBreakerMatchingTruthLieTutorial',
    data() {
      return {
        items: [
          ['Test 1', 'Test 2', 'Test 3'],
          ['Test 4', 'Test 5', 'Test 6']
        ],
        show: true,
        selected: []
      };
    },
    mounted() {
      setTimeout(() => (this.selected = ['Test 1']), 1000);

      setTimeout(() => (this.selected = ['Test 1', 'Test 5']), 2500);

      setTimeout(() => (this.show = false), 4000);
    }
  };
</script>

<style scoped lang="scss">
  .funicebreaker-matching-truthlie-tutorial {
    background: white;
    padding: 4px 12px;
    border-radius: 10px;

    .item {
      padding: 3px 2px;
    }

    .answer {
      @include font(10px, 500);
      color: black;
      border-radius: 12px;
      border: 1px solid color(accent);
      padding: 2px 5px;
      text-align: center;
      margin: 4px;
      cursor: pointer;
      transition: 0.3s ease;

      &.selected {
        background: color(accent);
        color: white;
      }
    }
  }
</style>
