import { useStore } from 'vuex';
import firebase from 'firebase';
import { computed, ComputedRef } from 'vue';
import {
  emptyFunIceBreakerGame,
  emptyFunIceBreakerPlayer,
  FUN_ICE_BREAKER_PHASES
} from '@/helpers/funicebreaker/initials';
import { Avatar, Player } from '@/interfaces/businessgame';
import {
  FunIceBreakerPlayerMatching,
  FunIceBreakerPlayer,
  FunIceBreakerQuestionConfig,
  FunIceBreakerGuessingOptionAnswer,
  FunIceBreakerGuessingOption,
  FunIceBreakerPointsPreviousRound
} from '@/interfaces/funicebreaker';
import { GAME_NAMES } from '@/helpers';
import { useRouter } from 'vue-router';
import useToast from './use-toast';
import { useI18n } from 'vue-i18n';

export default function () {
  const store = useStore();
  const db = firebase.firestore();
  const { push } = useRouter();
  const { infoToast } = useToast();
  const { t } = useI18n();

  const user = computed(() => store.state.user);

  const getCurrentCompany = async (companyId: string | string[] | null = null) => {
    let company;

    if (companyId) {
      company = await db
        .doc(`companies/${companyId}`)
        .get()
        .then(doc => {
          return { id: doc.id, ...doc.data() };
        });
    } else {
      company = await db
        .collection('companies')
        .where('userEmails', 'array-contains', user.value?.email)
        .get()
        .then((querySnapshot: any) => {
          const resultArray: any = [];

          querySnapshot.forEach((doc: any) => {
            resultArray.push({ id: doc.id, ...doc.data() });
          });

          return resultArray[0];
        });
    }

    document.documentElement.style.setProperty('--color-accent', `${company.brandColor}`);

    store.commit('setCompany', company);
  };

  const getOneFunIceBreakerGameAndListen = async (gameId: string) => {
    try {
      await db
        .collection(`companies/${store.state.company.id}/funIceBreakerGames`)
        .doc(gameId)
        .onSnapshot(doc => {
          store.commit('setCurrentFunIceBreakerGame', {
            ...doc.data(),
            id: doc.id
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getOneFunIceBreakerGame = async (gameId: string) => {
    return await db
      .collection(`companies/${store.state.company.id}/funIceBreakerGames`)
      .doc(gameId)
      .get()
      .then(doc => {
        return { id: doc.id, ...doc.data() };
      });
  };

  const updateGame = async (game: any) => {
    const docRef = await db
      .collection(`companies/${store.state.company.id}/funIceBreakerGames`)
      .doc(game.id);

    return await docRef
      .update({
        ...game,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        return docRef.get();
      })
      .then(doc => {
        return { id: doc.id, ...doc.data() };
      });
  };

  const playerHasJoinedTheGame: ComputedRef<boolean> = computed(() => {
    return (
      store.state.currentFunIceBreakerGame.players.find(
        (player: Player) => player.id === user.value?.id
      ) || store.state.currentFunIceBreakerGame.gameCreator?.id === user.value?.id
    );
  });

  const loggedInUserIsAPlayer: ComputedRef<boolean> = computed(() => {
    return store.state.currentFunIceBreakerGame.players.some(
      (player: FunIceBreakerPlayer) => player.id === user.value?.id
    );
  });

  const showJoinGame: ComputedRef<boolean> = computed(() => {
    if (!user.value?.id) {
      return true;
    }

    if (store.state.currentFunIceBreakerGame.gameCreator?.id === user.value?.id) {
      return false;
    }

    return Boolean(
      store.state.currentFunIceBreakerGame &&
        store.state.currentFunIceBreakerGame.players &&
        ['WELCOME', FUN_ICE_BREAKER_PHASES.QUESTION.value].includes(
          store.state.currentFunIceBreakerGame.phase
        ) &&
        user.value?.id &&
        !playerHasJoinedTheGame.value
    );
  });

  const showStartGameButton: ComputedRef<boolean> = computed(
    () =>
      store.state.currentFunIceBreakerGame &&
      !store.state.currentFunIceBreakerGame.gameStarted &&
      !store.state.currentFunIceBreakerGame.gameCancelled &&
      store.state.currentFunIceBreakerGame.players &&
      store.state.currentFunIceBreakerGame.players.length > 2 &&
      user &&
      store.state.currentFunIceBreakerGame.players.find(
        (player: FunIceBreakerPlayer) => player.admin
      ).id === user.value?.id
  );

  const currentPlayer: ComputedRef<FunIceBreakerPlayer> = computed(() => {
    return store.state.currentFunIceBreakerGame.players.find(
      (player: FunIceBreakerPlayer) => player.id === user.value?.id
    );
  });

  const joinGame = async (payload: {
    name: string;
    nickname: string;
    job: string;
    avatar: Avatar;
  }) => {
    if (!payload.name) {
      return;
    }
    if (!user.value?.id) {
      await firebase.auth().signInAnonymously();
    }
    await updateGame({
      ...store.state.currentFunIceBreakerGame,
      players: firebase.firestore.FieldValue.arrayUnion(
        ...[
          {
            ...emptyFunIceBreakerPlayer,
            id: user.value?.id,
            email: user.value?.email,
            admin: !store.state.currentFunIceBreakerGame.players.length,
            ...payload
          }
        ]
      )
    });

    if (!store.state.currentFunIceBreakerGame.players.length) {
      infoToast(t('newGame.firstPlayerToBeAdmin'), 6000);
      store.commit('toggleSound', true);
    }
  };

  const startGame = async () => {
    await updateGame({
      id: store.state.currentFunIceBreakerGame.id,
      gameStarted: true,
      noOfQuestionsLeft: store.state.currentFunIceBreakerGame.noOfQuestionsLeft,
      phase: 'QUESTION',
      activeQuestion: store.state.currentFunIceBreakerGame.questions[0]
    });
  };

  const cancelGame = async () => {
    try {
      await db.doc(`companies/${store.state.company.id}`).update({
        package: {
          ...store.state.company.package,
          gamesLeft: {
            ...store.state.company.package.gamesLeft,
            [GAME_NAMES.FUN_ICE_BREAKER]:
              store.state.company.package.gamesLeft[GAME_NAMES.FUN_ICE_BREAKER] + 1
          }
        }
      });

      await db
        .collection(`companies/${store.state.company.id}/funIceBreakerGames`)
        .doc(store.state.currentFunIceBreakerGame.id)
        .update({
          gameCancelled: true
        });
    } catch (e) {
      console.log(e);
    }

    push('/dashboard');
  };

  const startNextRound = async () => {
    await updateGame({
      id: store.state.currentFunIceBreakerGame.id,
      phase: 'QUESTION',
      activeQuestion: store.state.currentFunIceBreakerGame.questions[0],
      noOfQuestionsLeft: store.state.currentFunIceBreakerGame.noOfQuestionsLeft - 1,
      activeGuessingOptions: [],
      playersMatching: []
    });
  };

  const endGame = async () => {
    await updateGame({
      id: store.state.currentFunIceBreakerGame.id,
      phase: 'WELCOME',
      gameEnded: true,
      activeGuessingOptions: [],
      playersMatching: []
    });
  };

  const answerQuestion = async (answer: FunIceBreakerGuessingOptionAnswer) => {
    const game = store.state.currentFunIceBreakerGame;

    if (!loggedInUserIsAPlayer.value) {
      return;
    }

    await updateGame({
      id: game.id,
      activeGuessingOptions: firebase.firestore.FieldValue.arrayUnion(
        ...[
          {
            player: currentPlayer.value,
            answer
          }
        ]
      ),
      phase:
        game.activeGuessingOptions.length === game.players.length - 1 ? 'MATCHING' : 'QUESTION',
      playersMatchingStartTime:
        game.activeGuessingOptions.length === game.players.length - 1
          ? Date.now() + store.state.timeOffset
          : store.state.currentFunIceBreakerGame.playersMatchingStartTime
    });
  };

  const goToMatchingPhase = async () => {
    await updateGame({
      id: store.state.currentFunIceBreakerGame.id,
      phase: 'MATCHING',
      playersMatchingStartTime: Date.now() + store.state.timeOffset
    });
  };

  const allPlayersAnsweredQuestion: ComputedRef<boolean> = computed(() => {
    return (
      store.state.currentFunIceBreakerGame.activeGuessingOptions.length ===
      store.state.currentFunIceBreakerGame.players.length
    );
  });

  const submitMatching = async (matching: FunIceBreakerPlayerMatching, points: number) => {
    const game = store.state.currentFunIceBreakerGame;

    if (!loggedInUserIsAPlayer.value) {
      return;
    }

    const lastPlayerSubmitting = game.playersMatching.length === game.players.length - 1;

    await updateGame({
      id: game.id,
      playersMatching: firebase.firestore.FieldValue.arrayUnion(
        ...[
          {
            player: currentPlayer.value,
            matching: matching
          }
        ]
      ),
      pointsInPreviousRound: firebase.firestore.FieldValue.arrayUnion(
        ...[
          {
            playerId: currentPlayer.value.id,
            points
          }
        ]
      )
    });

    if (lastPlayerSubmitting) {
      setTimeout(async () => {
        await goToStoryPhase();
      }, 500);
    }
  };

  const goToStoryPhase = async () => {
    const game = store.state.currentFunIceBreakerGame;

    await updateGame({
      id: game.id,
      phase: 'STORY',
      storyPlayerIndex: Math.floor(Math.random() * game.players.length),
      players: game.players.map((p: FunIceBreakerPlayer) => ({
        ...p,
        pointsInPreviousRound:
          game.pointsInPreviousRound.find(
            (item: FunIceBreakerPointsPreviousRound) => item.playerId === p.id
          )?.points || 0,
        points:
          p.points +
          (game.pointsInPreviousRound.find(
            (item: FunIceBreakerPointsPreviousRound) => item.playerId === p.id
          )?.points || 0)
      })),
      questions: game.questions.filter(
        (q: FunIceBreakerQuestionConfig) => q.id !== game.activeQuestion.id
      ),
      pointsInPreviousRound: []
    });
  };

  const allPlayersDidTheirMatching: ComputedRef<boolean> = computed(() => {
    return (
      store.state.currentFunIceBreakerGame.playersMatching.length ===
      store.state.currentFunIceBreakerGame.players.length
    );
  });

  const deletePlayer = async (playerId: string) => {
    const newPlayers = store.state.currentFunIceBreakerGame.players.filter(
      (p: FunIceBreakerPlayer) => p.id !== playerId
    );
    const newPlayersMatching = store.state.currentFunIceBreakerGame.playersMatching.filter(
      (item: FunIceBreakerPlayerMatching) => item.player.id !== playerId
    );
    const newActiveGuessingOptions =
      store.state.currentFunIceBreakerGame.activeGuessingOptions.filter(
        (item: FunIceBreakerGuessingOption) => item.player.id !== playerId
      );

    let phase = store.state.currentFunIceBreakerGame.phase;

    if (newPlayersMatching.length === newPlayers.length) {
      phase = 'STORY';
    }

    if (newActiveGuessingOptions.length === newPlayers.length) {
      phase = 'MATCHING';
    }

    await updateGame({
      id: store.state.currentFunIceBreakerGame.id,
      players: newPlayers,
      playersMatching: newPlayersMatching,
      activeGuessingOptions: newActiveGuessingOptions,
      phase
    });
  };

  const addAdmin = async (id: string) => {
    const game = store.state.currentFunIceBreakerGame;
    const playerIndex = game.players.findIndex((p: FunIceBreakerPlayer) => p.id === id);

    await updateGame({
      id: game.id,
      players: [
        ...game.players.slice(0, playerIndex),
        {
          ...game.players[playerIndex],
          admin: true
        },
        ...game.players.slice(playerIndex + 1)
      ]
    });
  };

  return {
    showJoinGame,
    showStartGameButton,
    currentPlayer,
    allPlayersAnsweredQuestion,
    allPlayersDidTheirMatching,
    playerHasJoinedTheGame,
    loggedInUserIsAPlayer,
    startGame,
    joinGame,
    getOneFunIceBreakerGame,
    updateGame,
    getOneFunIceBreakerGameAndListen,
    getCurrentCompany,
    answerQuestion,
    submitMatching,
    startNextRound,
    endGame,
    cancelGame,
    goToMatchingPhase,
    goToStoryPhase,
    deletePlayer,
    addAdmin
  };
}
