
  import { PropType } from 'vue';
  import { FunIceBreakerGame } from '@/interfaces/funicebreaker';

  import FunIceBreakerCurrentResults from '@/components/funicebreaker/FunIceBreakerCurrentResults.vue';
  import FunIceBreakerLogo from '@/components/funicebreaker/FunIceBreakerLogo.vue';

  export default {
    name: 'FunIceBreakerLeftNavigation',
    components: { FunIceBreakerCurrentResults, FunIceBreakerLogo },
    props: {
      game: {
        type: Object as PropType<FunIceBreakerGame>,
        default: () => []
      }
    }
  };
