<template>
  <div v-if="show" class="funicebreaker-matching-single-tutorial ml1">
    <transition-group name="flip-list" tag="div">
      <div v-for="name in names" class="item flex items-center" :key="name">
        <img :src="require(`@/assets/images/match-drag.svg`)" alt="Drag icon" class="move" />
        <div class="name ml1">{{ name }}</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
  export default {
    name: 'FunIceBreakerMatchingSingleTutorial',
    data() {
      return {
        names: ['Anna', 'Jake', 'Adam'],
        show: true
      };
    },
    mounted() {
      setTimeout(() => (this.names = ['Jake', 'Adam', 'Anna']), 1000);

      setTimeout(() => (this.names = ['Jake', 'Anna', 'Adam']), 2700);

      setTimeout(() => (this.show = false), 4500);
    }
  };
</script>

<style scoped lang="scss">
  .funicebreaker-matching-single-tutorial {
    background: white;
    padding: 4px 12px;
    border-radius: 10px;

    .item {
      padding: 3px 2px;
    }

    img {
      width: global-vars(ui-default-measure2x);
      height: global-vars(ui-default-measure2x);
      box-shadow: global-vars(ui-default-box-shadow--light);
      border: 1px solid $teamicebreaker-primary;
      border-radius: 3px;
    }

    .name {
      @include font(12px, 500);
      color: black;
    }

    .flip-list-move {
      transition: transform 0.6s ease;
    }
  }
</style>
