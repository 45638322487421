<template>
  <div
    class="phase-matching"
    :class="{
      'phase-matching--quite-lots-of-players': players.length > 5 && players.length <= 8,
      'phase-matching--lots-of-players': players.length > 8
    }"
  >
    <div class="mb2 title flex items-center justify-center center color-white text-shadow">
      {{
        game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE
          ? $t('funIceBreaker.matching.titleSingle')
          : $t('funIceBreaker.matching.titleTruthLie')
      }}
      <FunIceBreakerMatchingSingleTutorial
        v-if="
          (game.initialNoOfQuestions === game.noOfQuestionsLeft ||
            game.initialNoOfQuestions - 1 === game.noOfQuestionsLeft) &&
          game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE
        "
      />
      <FunIceBreakerMatchingTruthLieTutorial
        v-if="
          (game.initialNoOfQuestions === game.noOfQuestionsLeft ||
            game.initialNoOfQuestions - 1 === game.noOfQuestionsLeft) &&
          game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE
        "
      />
    </div>
    <Button @click="goToStoryPhase" v-if="currentPlayer?.admin && allPlayersDidTheirMatching">
      {{ $t('common.manualNextPhase') }}
    </Button>
    <FunIceBreakerBox v-if="players.length" class="flex flex-column justify-between items-center">
      <div
        v-if="loggedInUserIsAPlayer"
        class="flex items-stretch matching-item-wrapper"
        :class="{
          'matching-item-wrapper--single':
            game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE,
          'matching-item-wrapper--disabled': Boolean(submittedMatching)
        }"
      >
        <draggable
          v-if="game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE"
          :list="players"
          item-key="name"
          ghost-class="ghost"
          tag="transition-group"
          :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
          v-bind="dragOptions"
          :move="handleMove"
          @end="handleDragEnd"
        >
          <template #item="{ element }">
            <div class="flex items-center matching-item matching-item--movable">
              <img :src="require(`@/assets/images/match-drag.svg`)" alt="" class="move" />
              <FunIceBreakerPhaseMatchingName :name="element.name" />
            </div>
          </template>
        </draggable>
        <div v-else class="flex flex-column justify-between players-truth-lie">
          <div v-for="player in players" :key="player.id">
            <FunIceBreakerPhaseMatchingName :name="player.name" />
          </div>
        </div>
        <FunIceBreakerPhaseMatchingAnswersSingle
          v-if="game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE"
          :answers="mappedAnswers"
        />
        <FunIceBreakerPhaseMatchingAnswersTruthLie
          v-else
          :answers="mappedAnswers"
          :selected="truthLieAnswers"
          :submitted="Boolean(submittedMatching)"
          @answer="truthLieAnswers[$event.tableIndex] = $event.answer"
        />
      </div>
      <div class="mt3">
        <b v-if="Boolean(submittedMatching)">
          {{ $t('funIceBreaker.waitForOtherPlayers') }}
        </b>
        <Button
          v-if="!Boolean(submittedMatching) && loggedInUserIsAPlayer"
          @click="handleSubmitMatching"
          :disabled="
            game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE
              ? false
              : noTruthLieAnswers
          "
        >
          {{ $t('funIceBreaker.matching.send') }}
        </Button>
        <b v-if="!loggedInUserIsAPlayer">
          {{ $t('funIceBreaker.youAreGameCreator') }}
        </b>
      </div>
    </FunIceBreakerBox>
    <div
      v-if="loggedInUserIsAPlayer"
      class="center mt2 color-white text-shadow"
      :style="{ opacity: !Boolean(submittedMatching) && secondsPassed > 0 ? 1 : 0 }"
    >
      {{ $t('funIceBreaker.matching.youWillGetPoints', { value: timePoints }) }}
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';

  import FunIceBreakerBox from '@/components/funicebreaker/FunIceBreakerBox';
  import useFirebaseFunIceBreaker from '@/composables/use-firebase-funicebreaker';
  import FunIceBreakerPhaseMatchingName from '@/components/funicebreaker/matching/FunIceBreakerPhaseMatchingName';
  import FunIceBreakerMatchingSingleTutorial from '@/components/funicebreaker/matching/FunIceBreakerMatchingSingleTutorial';
  import FunIceBreakerMatchingTruthLieTutorial from '@/components/funicebreaker/matching/FunIceBreakerMatchingTruthLieTutorial';
  import FunIceBreakerPhaseMatchingAnswersSingle from '@/components/funicebreaker/matching/FunIceBreakerPhaseMatchingAnswersSingle';
  import FunIceBreakerPhaseMatchingAnswersTruthLie from '@/components/funicebreaker/matching/FunIceBreakerPhaseMatchingAnswersTruthLie';
  import { FUN_ICE_BREAKER_QUESTION_TYPES } from '@/interfaces/funicebreaker';
  import useToast from '@/composables/use-toast';
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'FunIceBreakerPhaseMatching',
    components: {
      FunIceBreakerBox,
      draggable,
      FunIceBreakerPhaseMatchingName,
      FunIceBreakerMatchingSingleTutorial,
      FunIceBreakerMatchingTruthLieTutorial,
      FunIceBreakerPhaseMatchingAnswersSingle,
      FunIceBreakerPhaseMatchingAnswersTruthLie
    },
    props: {
      game: {
        type: Object,
        default: () => ({})
      },
      user: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        interval: null,
        secondsPassed: 0,
        futureItem: null,
        movingItem: null,
        futureIndex: null,
        movingIndex: null,
        truthLieAnswers: [],
        FUN_ICE_BREAKER_QUESTION_TYPES: FUN_ICE_BREAKER_QUESTION_TYPES,
        players: [],
        limitedOptions: []
      };
    },
    mounted() {
      if (this.$store.state.soundOn) {
        if (this.game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE) {
          this.speak(this.$i18n.t('funIceBreaker.matching.speakSingle'));
        } else {
          this.speak(this.$i18n.t('funIceBreaker.matching.speakTruthLie'));
        }
      }

      this.limitedOptions = [...this.game.activeGuessingOptions]
        .filter(item => item.player.id !== this.user.uid)
        .sort(() => Math.random() - 0.5)
        .slice(
          0,
          this.game.matchingLimit ? this.game.matchingLimit : this.game.activeGuessingOptions.length
        );

      setInterval(() => this.updateSecondsPassed(), 1000);

      if (this.game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE) {
        this.truthLieAnswers = this.limitedOptions
          .filter(item => item.player.id !== this.user.uid)
          .map(() => null);
      }

      let players = [];

      if (!this.submittedMatching) {
        players = this.limitedOptions.map(item => ({
          ...item.player,
          name: item.player.name
        }));
      } else {
        players = this.submittedMatching.matching.map(item => ({
          ...item.player,
          name: item.player.name
        }));
      }

      if (!this.submittedMatching) {
        if (this.game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE) {
          players.sort(() => Math.random() - 0.5);
        }
      }

      this.checkForSimilarAnswers(
        players,
        this.limitedOptions
          .filter(item => item.player.id !== this.user.uid)
          .map(item => item.answer)
      );

      this.players = players;
    },
    beforeUnmount() {
      clearInterval(this.interval);
    },
    computed: {
      noTruthLieAnswers() {
        return !this.truthLieAnswers.length || this.truthLieAnswers.some(a => !a);
      },
      dragOptions() {
        return {
          animation: 0,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost'
        };
      },
      submittedMatching() {
        return this.game.playersMatching.find(matching => matching.player.id === this.user.uid);
      },
      mappedAnswers() {
        return this.limitedOptions.map(item => item.answer);
      },
      currentMatching() {
        if (this.game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE) {
          return this.players.map((player, index) => {
            return {
              player,
              answer: this.mappedAnswers[index],
              validAnswer: this.getGoodAnswer(player),
              isMatch:
                this.getGoodAnswer(player).toLowerCase() === this.mappedAnswers[index].toLowerCase()
            };
          });
        } else {
          return this.players.map((player, index) => ({
            player,
            answer: this.truthLieAnswers[index],
            validAnswer: this.mappedAnswers[index][2],
            isMatch: this.mappedAnswers[index][2] === this.truthLieAnswers[index]
          }));
        }
      },
      timePoints() {
        const realPoints = 1000 - 5 * this.secondsPassed;

        return realPoints > 100 ? realPoints : 100;
      }
    },
    methods: {
      speak(message) {
        const msg = new SpeechSynthesisUtterance(message);
        msg.lang = this.$i18n.locale === 'pl' ? 'pl-PL' : 'en-GB';

        window.speechSynthesis.speak(msg);
      },
      handleDragEnd() {
        this.futureItem = this.players[this.futureIndex];
        this.movingItem = this.players[this.movingIndex];
        const _players = Object.assign([], this.players);
        _players[this.futureIndex] = this.movingItem;
        _players[this.movingIndex] = this.futureItem;

        this.players = _players;
      },
      handleMove(e) {
        const { index, futureIndex } = e.draggedContext;
        this.movingIndex = index;
        this.futureIndex = futureIndex;
        return false; // disable sort
      },
      updateSecondsPassed() {
        this.secondsPassed = Math.round(
          (Date.now() + this.$store.state.timeOffset - this.game.playersMatchingStartTime) / 1000
        );
      },
      getGoodAnswer(player) {
        const playerAnswerObject = this.game.activeGuessingOptions.find(
          o => o.player.id === player.id
        );

        return playerAnswerObject.answer;
      },
      handleSubmitMatching() {
        const pointsForRound = Number(
          this.timePoints * this.currentMatching.filter(m => m.isMatch).length
        );

        this.submitMatching(this.currentMatching, pointsForRound);
      }
    },
    setup(props) {
      const {
        submitMatching,
        currentPlayer,
        goToStoryPhase,
        allPlayersDidTheirMatching,
        loggedInUserIsAPlayer
      } = useFirebaseFunIceBreaker();

      const { infoToast } = useToast();
      const { t } = useI18n();

      const checkForSimilarAnswers = (players, mappedAnswers) => {
        if (props.game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE) {
          const mapped = players.map((p, index) => mappedAnswers[index].toLowerCase());
          const mappedAnswersSet = new Set(mapped);

          if (mapped.length !== mappedAnswersSet.size) {
            infoToast(
              `${t('funIceBreaker.matching.similarAnswers.0')}\n\n${t(
                'funIceBreaker.matching.similarAnswers.1'
              )}`,
              7000
            );
          }
        }

        return false;
      };

      return {
        currentPlayer,
        loggedInUserIsAPlayer,
        allPlayersDidTheirMatching,
        submitMatching,
        goToStoryPhase,
        checkForSimilarAnswers
      };
    }
  };
</script>

<style scoped lang="scss">
  .title {
    @include font(24px, 600);
  }

  .move {
    box-shadow: global-vars(ui-default-box-shadow--light);
    margin: 0 8px;
    height: global-vars(ui-default-measure3x);
    border: 1px solid $teamicebreaker-primary;
    border-radius: 3px;
  }

  .match-arrow {
    margin: 0 32px;
    width: 150px;

    @include mobile() {
      display: none;
    }
  }

  .matching-item {
    padding: 8px 0;
    height: global-vars(ui-default-measure5x);
    border-bottom: 1px solid color(gray-background);

    &-wrapper {
      @include mobile() {
        width: 100%;
      }

      &--disabled {
        pointer-events: none;
      }

      &--single {
        @include mobile() {
          flex-direction: row;
          width: 100%;
        }

        .name {
          @include mobile() {
            text-align: left;
          }
        }
      }
    }

    &--movable {
      cursor: move;
    }

    .name {
      margin-left: global-vars(ui-default-measure);
      @include font(15px, 600, 28px);
    }

    .nickname {
      margin-left: global-vars(ui-default-measure);
      @include font(12px, 600, 18px);
    }

    .answer {
      width: 200px;
      @include font(15px, 500, 28px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include xs-down() {
        width: 140px;
      }
    }
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .players-truth-lie {
    height: 100%;

    @include mobile() {
      padding: 4px 0 0;
    }

    > div {
      height: global-vars(ui-default-measure5x);
      display: flex;
      align-items: center;
    }
  }
</style>

<style lang="scss">
  .phase-matching {
    &--quite-lots-of-players {
      .matching-item {
        height: 35px !important;
      }

      .move {
        height: 20px;
      }
    }

    &--lots-of-players {
      .matching-item {
        height: 30px !important;
      }

      .move {
        height: global-vars(ui-default-measure2x);
      }
    }
  }

  .matching-item {
    &-wrapper {
      &--single {
        .name-nickname .name {
          @include mobile() {
            text-align: left;
          }
        }
      }
    }
  }
</style>
