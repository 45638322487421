<template>
  <div>
    <div v-for="(_, index) in answers" :key="index" class="flex items-center matching-item">
      <img class="match-arrow" :src="require(`@/assets/images/arrow-answer.svg`)" alt="" />
      <BaseTooltipWrapper :tooltip="answers[index]">
        <div class="answer">{{ answers[index] }}</div>
      </BaseTooltipWrapper>
    </div>
  </div>
</template>

<script>
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper';

  export default {
    name: 'FunIceBreakerPhaseMatchingAnswersSingle',
    components: {
      BaseTooltipWrapper
    },
    props: {
      answers: {
        type: Array,
        default: () => []
      }
    }
  };
</script>

<style scoped lang="scss">
  .matching-item {
    padding: 8px 0;
    height: global-vars(ui-default-measure5x);
    border-bottom: 1px solid color(gray-background);

    .answer {
      width: 200px;
      @include font(15px, 500, 28px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include xs-down() {
        width: 140px;
      }
    }

    .match-arrow {
      margin: 0 32px;
      width: 150px;

      @include mobile() {
        display: none;
      }
    }
  }
</style>
