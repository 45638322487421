<template>
  <div class="flex flex-column items-center">
    <Button @click="goToMatchingPhase" v-if="currentPlayer?.admin && allPlayersAnsweredQuestion">
      {{ $t('common.manualNextPhase') }}
    </Button>
    <FunIceBreakerBox class="phase-question flex flex-column justify-between items-center">
      <div class="mb2 question center">
        {{ $i18n.locale === 'pl' ? game.activeQuestion.textPl : game.activeQuestion.textEn }}
        <span v-if="game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE">
          &nbsp;{{ $t('funIceBreaker.question.write2Truths1Lie') }}
        </span>
      </div>
      <Input
        v-if="
          game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE &&
          loggedInUserIsAPlayer
        "
        type="text"
        class="answer"
        v-model="answer"
        :placeholder="$t('funIceBreaker.question.placeholder')"
        :disabled="Boolean(userAnswerObject)"
        @keyup.enter="keyupEnterHandleQuestionAnswer"
      />
      <div
        v-if="
          game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE &&
          loggedInUserIsAPlayer
        "
        class="phase-question-truth-lie"
      >
        <Input
          v-for="i in 3"
          :key="i"
          type="text"
          class="answer"
          :class="i < 3 ? 'answer-truth' : 'answer-lie'"
          v-model="truthLieAnswers[i - 1]"
          :placeholder="
            i < 3
              ? $t('funIceBreaker.question.truthPlaceholder')
              : $t('funIceBreaker.question.liePlaceholder')
          "
          :disabled="Boolean(userAnswerObject)"
        />
      </div>
      <div class="action mt2">
        <b v-if="Boolean(userAnswerObject)">
          {{ $t('funIceBreaker.waitForOtherPlayers') }}
        </b>
        <Button
          v-if="!Boolean(userAnswerObject) && loggedInUserIsAPlayer"
          :disabled="buttonDisabled"
          @click="handleAnswerQuestion"
        >
          {{ $t('funIceBreaker.question.answer') }}
        </Button>
        <b v-if="!loggedInUserIsAPlayer">
          {{ $t('funIceBreaker.youAreGameCreator') }}
        </b>
      </div>
    </FunIceBreakerBox>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';

  import FunIceBreakerBox from '@/components/funicebreaker/FunIceBreakerBox';
  import useFirebaseFunIceBreaker from '@/composables/use-firebase-funicebreaker';
  import { FUN_ICE_BREAKER_QUESTION_TYPES } from '@/interfaces/funicebreaker';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'FunIceBreakerPhaseQuestion',
    components: { FunIceBreakerBox },
    props: {
      game: {
        type: Object,
        default: () => ({})
      },
      user: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        FUN_ICE_BREAKER_QUESTION_TYPES: FUN_ICE_BREAKER_QUESTION_TYPES
      };
    },
    setup(props) {
      const {
        answerQuestion,
        currentPlayer,
        goToMatchingPhase,
        allPlayersAnsweredQuestion,
        loggedInUserIsAPlayer
      } = useFirebaseFunIceBreaker();
      const store = useStore();
      const { t, locale } = useI18n();

      const answer = ref('');
      const truthLieAnswers = ref([]);

      const userAnswerObject = computed(() => {
        return props.game.activeGuessingOptions.find(
          option => option.player.id === props.user?.uid
        );
      });

      const buttonDisabled = computed(() => {
        if (!loggedInUserIsAPlayer.value) {
          return true;
        }

        return props.game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE
          ? !answer.value
          : truthLieAnswers.value.length < 3;
      });

      const speak = message => {
        const msg = new SpeechSynthesisUtterance(message);
        msg.lang = locale.value === 'en' ? 'en-GB' : 'pl-PL';

        window.speechSynthesis.speak(msg);
      };

      onMounted(() => {
        if (userAnswerObject.value) {
          answer.value = userAnswerObject.value.answer;
        }

        if (store.state.soundOn) {
          speak(t('common.nextRound'));
        }
      });

      const handleAnswerQuestion = () => {
        answerQuestion(
          props.game.activeQuestion.type === FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE
            ? answer.value
            : truthLieAnswers.value
        );
      };

      const keyupEnterHandleQuestionAnswer = () => {
        if (answer.value) {
          handleAnswerQuestion();
        }
      };

      return {
        answer,
        userAnswerObject,
        truthLieAnswers,
        currentPlayer,
        allPlayersAnsweredQuestion,
        loggedInUserIsAPlayer,
        buttonDisabled,
        handleAnswerQuestion,
        goToMatchingPhase,
        keyupEnterHandleQuestionAnswer
      };
    }
  };
</script>

<style scoped lang="scss">
  .phase-question {
    .question {
      @include font(24px, 600);
      max-width: 450px;
      min-width: 300px;
    }

    &-truth-lie {
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 132px;
    }

    .answer {
      padding: global-vars(ui-default-measure2x);
      background: #f5f5f5;
      border-radius: global-vars(ui-default-measure2x);
      border: none;
      width: 100%;
      height: 36px;

      &-truth {
        border: 1px solid color(green);
      }

      &-lie {
        border: 1px solid color(red);
      }
    }

    input {
      margin: 4px 0;
    }
  }
</style>
