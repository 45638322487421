
  import { PropType, computed, watch, ref, ComputedRef } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useConfirm } from 'primevue/useconfirm';

  import {
    FunIceBreakerGame,
    FunIceBreakerGuessingOption,
    FunIceBreakerPlayer,
    FunIceBreakerPlayerMatching
  } from '@/interfaces/funicebreaker';
  import { FUN_ICE_BREAKER_PHASES } from '@/helpers/funicebreaker/initials';
  import useFirebaseFunIceBreaker from '@/composables/use-firebase-funicebreaker';
  import useToast from '@/composables/use-toast';
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper.vue';

  export default {
    name: 'FunIceBreakerCurrentResults',
    components: { BaseTooltipWrapper },
    props: {
      game: {
        type: Object as PropType<FunIceBreakerGame>,
        default: () => []
      }
    },
    data() {
      return {
        FUN_ICE_BREAKER_PHASES
      };
    },
    setup(props: any) {
      const { successToast } = useToast();
      const { t } = useI18n();
      const confirm = useConfirm();
      const { infoToast } = useToast();
      const added = ref(false);

      const { currentPlayer, deletePlayer } = useFirebaseFunIceBreaker();

      const sortedPlayers: ComputedRef<FunIceBreakerPlayer[]> = computed(() => {
        return [...props.game?.players].sort((a, b) => b.points - a.points);
      });

      const playerWithMostPointsForLastRound: ComputedRef<FunIceBreakerPlayer> = computed(() => {
        return [...props.game?.players].sort(
          (a, b) => b.pointsInPreviousRound - a.pointsInPreviousRound
        )[0];
      });

      const checkIfPlayerCanBeDeleted = (id: string) => {
        return currentPlayer.value?.admin && currentPlayer.value?.id !== id;
      };

      watch(
        () => sortedPlayers.value?.[0],
        (newVal, oldVal) => {
          if (!newVal || !oldVal) {
            return;
          }

          if (newVal.name !== oldVal.name && props.game.phase !== 'WELCOME') {
            successToast(
              t('funIceBreaker.story.newLeader.0', { name: newVal.name }),
              4000,
              t('funIceBreaker.story.newLeader.1')
            );
          }
        }
      );

      watch(
        () => props.game.phase,
        newVal => {
          if (
            newVal === FUN_ICE_BREAKER_PHASES.STORY.value &&
            playerWithMostPointsForLastRound.value.pointsInPreviousRound > 0
          ) {
            successToast(
              t('funIceBreaker.story.mostPoints.0', {
                name: playerWithMostPointsForLastRound.value.name,
                points: playerWithMostPointsForLastRound.value.pointsInPreviousRound
              }),
              4000,
              t('funIceBreaker.story.mostPoints.1')
            );
            added.value = true;

            setTimeout(() => (added.value = false), 1000);
          }
        }
      );

      const showIndicator = (playerId: string) => {
        if (props.game?.phase === FUN_ICE_BREAKER_PHASES.QUESTION.value) {
          return props.game?.activeGuessingOptions.some(
            (item: FunIceBreakerGuessingOption) => item.player.id === playerId
          );
        } else if (props.game?.phase === FUN_ICE_BREAKER_PHASES.MATCHING.value) {
          return props.game?.playersMatching.some(
            (item: FunIceBreakerPlayerMatching) => item.player.id === playerId
          );
        }

        return false;
      };

      const handleDeletePlayer = (playerId: string) => {
        confirm.require({
          message: '',
          header: t('common.areYouSure'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            deletePlayer(playerId);
            infoToast(t('common.playerRemoved'));
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });
      };

      return {
        currentPlayer,
        sortedPlayers,
        added,
        showIndicator,
        checkIfPlayerCanBeDeleted,
        handleDeletePlayer
      };
    }
  };
