<template>
  <div class="flex flex-column items-center name-nickname">
    <BaseTooltipWrapper :tooltip="$attrs.name">
      <div class="name">{{ $attrs.name }}</div>
    </BaseTooltipWrapper>
  </div>
</template>

<script>
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper';

  export default {
    name: 'FunIceBreakerPhaseMatchingName',
    components: {
      BaseTooltipWrapper
    }
  };
</script>

<style scoped lang="scss">
  .name-nickname {
    width: 140px;
    height: global-vars(ui-default-measure4x);

    @include xs-down() {
      width: 120px;
    }

    .name {
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include mobile() {
        width: 110px;
      }
    }
  }
</style>
