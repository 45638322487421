
  import { ref, computed, defineComponent, onMounted, Ref, ComputedRef } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  import {
    emptyFunIceBreakerPlayer,
    emptyFunIceBreakerGame
  } from '@/helpers/funicebreaker/initials';
  import useFirebase from '@/composables/use-firebase';
  import useToast from '@/composables/use-toast';
  import useFirebaseFunIceBreaker from '@/composables/use-firebase-funicebreaker';
  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import IconAttribute from '@/components/common/IconAttribute.vue';
  import LogoSpinner from '@/components/common/LogoSpinner.vue';

  import { GAME_NAMES, GAME_NAMES_HUMANIZED } from '@/helpers';
  import { Avatar } from '@/interfaces/businessgame';
  import { AvatarObject } from '@/constants/avatars';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'FunIceBreakerNewGame',
    components: { LanguageSelection, IconAttribute, LogoSpinner },
    setup() {
      const { getCurrentUser, addAlertThatNewGameStarted } = useFirebase();
      const user = getCurrentUser();
      const { getCurrentCompany } = useFirebaseFunIceBreaker();

      let { push, currentRoute } = useRouter();
      const store = useStore();
      const { successToast, warningToast, errorToast } = useToast();
      const { t } = useI18n();

      const loading: Ref = ref(false);
      const gameId: Ref<string> = ref('');
      const newGameLoading: Ref<boolean> = ref(false);
      const name: Ref<string> = ref('');
      const nickname: Ref<string> = ref('');
      const job: Ref<string> = ref('');
      const noOfQuestionsLeft: Ref<number> = ref(5);
      const matchingLimit: Ref<number> = ref(4);
      const selectedQuestions: Ref<any[]> = ref([]);
      const selectQuestionsManually: Ref<boolean> = ref(false);
      const selectedAvatar: Ref<Avatar | null> = ref(null);
      const creatorShouldPlayGame: Ref<boolean> = ref(true);
      const limitNumberOfMatching: Ref<boolean> = ref(false);

      const avatars: ComputedRef<AvatarObject> = computed(() => store.state.company.avatars);

      const questionsLength: ComputedRef = computed(() =>
        selectQuestionsManually.value ? selectedQuestions.value.length : noOfQuestionsLeft.value
      );

      const estimatedDuration: ComputedRef = computed(() => ({
        threeFive: `${questionsLength.value * 3 + 10} - ${questionsLength.value * 4 + 10}`,
        sixEight: `${questionsLength.value * 4 + 10} - ${questionsLength.value * 5 + 10}`,
        moreThanEight: `${questionsLength.value * 5 + 10} - ${questionsLength.value * 6 + 10}`
      }));

      const questions: ComputedRef = computed(() => company.value.funIceBreakerCustomQuestions);

      const company: ComputedRef = computed(() => store.state.company);

      const userHasPermissionsToCreateGames: ComputedRef = computed(
        () => store.state.user?.roles?.gameCreator
      );

      const buttonDisabled: ComputedRef = computed(() => {
        if (creatorShouldPlayGame.value) {
          return !name.value || !selectedAvatar.value || !userHasPermissionsToCreateGames.value;
        } else {
          return !userHasPermissionsToCreateGames.value;
        }
      });

      onMounted(async () => {
        loading.value = true;

        try {
          await getCurrentCompany();
          await getCurrentUserRoles();

          if (
            !store.state.company ||
            !store.state.company.package ||
            !store.state.company.package.gamesLeft[GAME_NAMES.FUN_ICE_BREAKER] ||
            store.state.company.package.gamesLeft[GAME_NAMES.FUN_ICE_BREAKER] === 0
          ) {
            errorToast(
              t('errors.noPackageOrGames', {
                name: GAME_NAMES_HUMANIZED.FUN_ICE_BREAKER
              })
            );
            newGameLoading.value = false;
            return;
            // logic to help user buy new ones
          }
        } catch (e) {
          errorToast(e);
        } finally {
          loading.value = false;
        }

        if (currentRoute.value.query?.questions) {
          selectQuestionsManually.value = true;
          selectedQuestions.value = questions.value.filter((q: any) =>
            currentRoute.value.query?.questions?.includes(q.id)
          );
          successToast(t('success.selectedQuestionsAdded'));
        }
      });

      const getCurrentUserRoles = async () => {
        const response = await db
          .doc(`companies/${store.state.company.id}/users/${user?.email}`)
          .get()
          .then(doc => doc.data());

        if (!response?.roles?.gameCreator) {
          warningToast(t('warnings.noPermissionsToCreateGames'));
        }
        store.commit('setCurrentUserRoles', response?.roles);
      };

      const createNewGame = async (game: any) => {
        delete game.id;

        return await db
          .collection(`companies/${store.state.company.id}/funIceBreakerGames`)
          .add({
            ...game,
            createdOn: Date.now()
          })
          .then(docRef => {
            return docRef.get();
          })
          .then(doc => {
            return doc.id;
          });
      };

      const removeOneGameFromPackage = async () => {
        await db.doc(`companies/${store.state.company.id}`).update({
          package: {
            ...store.state.company.package,
            gamesLeft: {
              ...store.state.company.package.gamesLeft,
              [GAME_NAMES.FUN_ICE_BREAKER]:
                store.state.company.package.gamesLeft[GAME_NAMES.FUN_ICE_BREAKER] - 1
            }
          }
        });
      };

      const addGame = async (): Promise<any> => {
        if ([name.value, nickname.value, job.value].some(v => v.length >= 25)) {
          warningToast(t('warnings.cantBeLongerThan', { value: 25 }));
          return;
        }

        if (newGameLoading.value) {
          return;
        }

        newGameLoading.value = true;

        // so that we have newest data
        await getCurrentCompany();

        if (
          !store.state.company ||
          !store.state.company.package ||
          !store.state.company.package.gamesLeft[GAME_NAMES.FUN_ICE_BREAKER] ||
          store.state.company.package.gamesLeft[GAME_NAMES.FUN_ICE_BREAKER] === 0
        ) {
          errorToast(
            t('errors.noPackageOrGames', {
              name: GAME_NAMES_HUMANIZED.FUN_ICE_BREAKER
            })
          );
          newGameLoading.value = false;
          return;
          // logic to help user buy new ones
        }

        let questionsModePayload;

        if (selectQuestionsManually.value) {
          questionsModePayload = {
            noOfQuestionsLeft: selectedQuestions.value.length,
            initialNoOfQuestions: selectedQuestions.value.length,
            questions: selectedQuestions.value
          };
        } else {
          const numberOfQuestions = Number(noOfQuestionsLeft.value);

          const ratioForTruthLieQuestions = 0.25;
          const truthLieQuestionsCount = Math.round(ratioForTruthLieQuestions * numberOfQuestions);
          const singleQuestionsLengthCount = numberOfQuestions - truthLieQuestionsCount;

          const questionsTruthLie = [...questions.value]
            .filter(q => q.type === 'TRUTHLIE')
            .sort(() => Math.random() - 0.5)
            .slice(0, truthLieQuestionsCount);

          const questionsSingle = [...questions.value]
            .filter(q => q.type === 'SINGLE')
            .sort(() => Math.random() - 0.5)
            .slice(0, singleQuestionsLengthCount);

          const questionsRandom = [...questionsTruthLie, ...questionsSingle].sort(
            () => Math.random() - 0.5
          );

          questionsModePayload = {
            noOfQuestionsLeft: Number(noOfQuestionsLeft.value),
            initialNoOfQuestions: Number(noOfQuestionsLeft.value),
            questions: questionsRandom
          };
        }

        if (selectQuestionsManually.value && selectedQuestions.value.length > 15) {
          errorToast(t('funIceBreaker.customQuestions.tooMany'));
          newGameLoading.value = false;
          return;
        }

        if (selectQuestionsManually.value && selectedQuestions.value.length < 3) {
          errorToast(t('funIceBreaker.customQuestions.tooFew'));
          newGameLoading.value = false;
          return;
        }

        const playerData = {
          ...emptyFunIceBreakerPlayer,
          name: name.value,
          nickname: nickname.value,
          job: job.value,
          email: user?.email || null,
          id: user?.uid,
          admin: true,
          avatar: selectedAvatar.value
        };

        const newDocument = {
          ...emptyFunIceBreakerGame,
          ...questionsModePayload,
          avatars: avatars.value,
          settings: {
            ...emptyFunIceBreakerGame.settings
          },
          matchingLimit: limitNumberOfMatching.value ? matchingLimit.value : null,
          players: creatorShouldPlayGame.value ? [playerData] : [],
          gameCreator: playerData
        };

        try {
          gameId.value = await createNewGame(newDocument);
          await removeOneGameFromPackage();
          await addAlertThatNewGameStarted(
            'FunIceBreaker',
            store.state.company.id,
            store.state.company.name,
            gameId.value
          );
          successToast(t('success.gameCreated'));
          store.commit('toggleSound', true);
          newGameLoading.value = false;
          goToGame();
        } catch (e) {
          newGameLoading.value = false;
          errorToast(e);
        }
      };

      const goToGame = (): void => {
        push({
          name: 'funicebreaker-game',
          params: { companyId: company.value.id, gameId: gameId.value }
        });
      };

      return {
        loading,
        name,
        nickname,
        job,
        user,
        avatars,
        selectedAvatar,
        newGameLoading,
        userHasPermissionsToCreateGames,
        company,
        noOfQuestionsLeft,
        matchingLimit,
        questions,
        selectedQuestions,
        selectQuestionsManually,
        creatorShouldPlayGame,
        limitNumberOfMatching,
        buttonDisabled,
        estimatedDuration,
        goToGame,
        addGame
      };
    }
  });
