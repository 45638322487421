import { Avatar } from '@/interfaces/businessgame';
import { AvatarObject } from '@/constants/avatars';

export enum FUN_ICE_BREAKER_QUESTION_TYPES {
  SINGLE = 'SINGLE',
  TRUTHLIE = 'TRUTHLIE'
}

export type FunIceBreakerQuestionType =
  | FUN_ICE_BREAKER_QUESTION_TYPES.SINGLE
  | FUN_ICE_BREAKER_QUESTION_TYPES.TRUTHLIE;

export interface FunIceBreakerGame {
  id: string;
  createdOn: number;
  players: FunIceBreakerPlayer[];
  settings: any;
  gameStarted: boolean;
  gameEnded: boolean;
  gameCancelled: boolean;
  endResults: any;
  questions: FunIceBreakerQuestionConfig[];
  activeQuestion: FunIceBreakerQuestionConfig | null;
  playersMatching: FunIceBreakerPlayerMatching[];
  playersMatchingStartTime: number;
  activeGuessingOptions: FunIceBreakerGuessingOption[];
  phase: string;
  noOfQuestionsLeft: number;
  matchingLimit: number | null;
  initialNoOfQuestions: number;
  avatars: AvatarObject;
  storyPlayerIndex: number;
  pointsInPreviousRound: FunIceBreakerPointsPreviousRound[];
  gameCreator: FunIceBreakerPlayer | null;
}

export interface FunIceBreakerPointsPreviousRound {
  playerId: string;
  points: number;
}

export interface FunIceBreakerSettings {
  defaultNoOfQuestions: number;
}

export interface FunIceBreakerPlayer {
  id: string;
  name: string;
  nickname: string;
  job: string;
  email: string;
  points: number;
  pointsInPreviousRound: number;
  admin: boolean;
  avatar: Avatar;
}

export type FunIceBreakerGuessingOptionAnswer = string | string[];

export interface FunIceBreakerGuessingOption {
  player: FunIceBreakerPlayer;
  answer: FunIceBreakerGuessingOptionAnswer;
}

export interface FunIceBreakerPlayerMatching {
  player: FunIceBreakerPlayer;
  matching: FunIceBreakerAnswerMatches[];
}

export interface FunIceBreakerAnswerMatches {
  player: FunIceBreakerPlayer;
  isMatch: boolean;
  answer: string;
  validAnswer: string;
}

export interface FunIceBreakerQuestionConfig {
  id: string;
  textPl: string;
  textEn: string;
  type: FunIceBreakerQuestionType;
}
