<template>
  <div class="top-nav flex justify-center">
    <FunIceBreakerPhase :phase="phase" :questions-left="questionsLeft" />
  </div>
</template>

<script>
  import FunIceBreakerPhase from '@/components/funicebreaker/FunIceBreakerPhase';

  export default {
    name: 'FunIceBreakerTopNav',
    components: { FunIceBreakerPhase },
    props: {
      phase: {
        type: String,
        default: ''
      },
      questionsLeft: {
        type: Number,
        default: null
      }
    }
  };
</script>

<style scoped lang="scss">
  .top-nav {
  }
</style>
