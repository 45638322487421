<template>
  <div class="flex items-center">
    <img
      :src="require(`@/assets/logos/logo-funicebreaker.svg`)"
      alt="Logo FunIceBreaker"
      class="mr2"
    />
    <span>FunIceBreaker by RemoteFun</span>
  </div>
</template>

<script>
  export default {
    name: 'FunIceBreakerLogo'
  };
</script>

<style scoped lang="scss">
  img {
    width: global-vars(ui-default-measure5x);
  }
</style>
