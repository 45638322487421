<template>
  <GameEnded :is-admin="isAdmin">
    <template #podium>
      <div class="flex game-ended__players">
        <div
          v-for="player in sortedResults.slice(0, 3)"
          :key="player.id"
          class="game-ended__player flex flex-column items-center justify-between mb2"
        >
          <div class="game-ended__player-info">
            <span class="game-ended__player-name color-white">
              {{ player.name }}
            </span>
          </div>
          <div class="game-ended__player-points color-white mt2">
            {{ player.points }} {{ $t(`funIceBreaker.points`) }}
          </div>
        </div>
      </div>
    </template>
    <template #rest>
      <div class="flex justify-center mt3">
        <div
          v-for="player in sortedResults.slice(3)"
          :key="player.id"
          class="game-ended__other-player flex flex-column items-center justify-between mb2"
        >
          <div class="game-ended__other-player-info">
            <span class="game-ended__other-player-name color-white">
              {{ player.name }}
            </span>
          </div>
          <div class="game-ended__other-player-points color-white mt2">
            {{ player.points }} {{ $t(`funIceBreaker.points`) }}
          </div>
        </div>
      </div>
    </template>
  </GameEnded>
</template>

<script>
  import GameEnded from '@/components/common/GameEnded';

  export default {
    name: 'FunIceBreakerGameEnded',
    components: {
      GameEnded
    },
    props: {
      results: {
        type: Array,
        default: () => []
      },
      isAdmin: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      sortedResults() {
        return [...this.results].sort((a, b) => b.points - a.points);
      }
    }
  };
</script>

<style scoped lang="scss">
  .game-ended {
    &__players {
      width: 1200px;

      @include md-down() {
        width: 750px;
      }

      @include mobile() {
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
    }

    &__player {
      width: 340px;
      margin: 0 30px;
      position: relative;
      padding: global-vars(ui-default-measure3x);
      border-radius: global-vars(ui-default-measure2x);

      @include md-down() {
        width: 230px;
        margin: 0 10px;
      }

      &:first-of-type {
        order: 2;
        top: -10px;

        @include mobile() {
          order: 1;
        }
      }
      &:nth-of-type(2) {
        order: 1;
        top: 70px;

        @include mobile() {
          order: 2;
        }
      }
      &:nth-of-type(3) {
        order: 3;
        top: 150px;

        @include mobile() {
          order: 3;
        }
      }

      @include mobile() {
        top: 0 !important;
        margin-bottom: global-vars(ui-default-measure2x);
      }

      &-name {
        @include font(24px, 500);
      }

      &-points {
        @include font(18px, 600);
      }
    }

    &__other-player {
      margin: 0 30px;
      position: relative;
      padding: global-vars(ui-default-measure3x);
      border-radius: global-vars(ui-default-measure2x);

      @include md-down() {
        width: 230px;
        margin: 0 10px;
      }

      @include mobile() {
        margin: 10px;
      }

      &-name {
        @include font(18px, 500);
      }

      &-points {
        @include font(16px, 600);
      }
    }
  }
</style>
