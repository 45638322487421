<template>
  <div class="truth-lie-matching-item-wrapper">
    <div
      v-for="(answerItem, index) in randomizedAnswers"
      :key="index"
      class="flex items-center matching-item"
      :class="{ disabled: Boolean(submitted) }"
    >
      <div class="flex items-center">
        <div
          v-for="answer in answerItem"
          :key="answer"
          class="answer"
          :class="answer === selected[index] && 'selected'"
          @click="$emit('answer', { tableIndex: index, answer })"
        >
          <BaseTooltipWrapper :tooltip="answer">
            <div>{{ answer }}</div>
          </BaseTooltipWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper';

  export default {
    name: 'FunIceBreakerPhaseMatchingAnswersTruthLie',
    components: {
      BaseTooltipWrapper
    },
    props: {
      answers: {
        type: Array,
        default: () => []
      },
      selected: {
        type: Array,
        default: () => []
      },
      submitted: {
        type: Boolean,
        default: () => false
      }
    },
    data() {
      return {
        randomizedAnswers: []
      };
    },
    mounted() {
      this.randomizedAnswers = this.answers.map(item => {
        return [...item].sort(() => Math.random() - 0.5);
      });
    }
  };
</script>

<style scoped lang="scss">
  .truth-lie-matching-item-wrapper {
    display: flex;
    flex-direction: column;
  }

  .matching-item {
    padding: 8px 0;
    height: global-vars(ui-default-measure5x);
    border-bottom: 1px solid color(gray-background);

    &.disabled {
      pointer-events: none;
    }

    .answer {
      width: 120px;
      @include font(13px, 600, 28px);
      border-radius: 12px;
      border: 2px solid color(accent);
      padding: 4px 8px;
      text-align: center;
      margin: 4px;
      cursor: pointer;

      @include mobile() {
        width: 100px;
      }

      @include xs-down() {
        width: 70px;
        padding: 4px 2px;
        @include font(11px, 600);
        margin: 2px;
      }

      div {
        width: 105px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include mobile() {
          width: 100%;
        }
      }

      &.selected {
        background: color(accent);
        color: white;
      }
    }
  }
</style>
