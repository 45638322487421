
  import { computed, defineComponent, PropType, ref, Ref } from 'vue';

  import { FunIceBreakerPlayer } from '@/interfaces/funicebreaker';
  import { AvatarObject } from '@/constants/avatars';
  import { Avatar } from '@/interfaces/businessgame';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';

  export default defineComponent({
    name: 'FunIceBreakerJoinGame',
    components: { BaseButtonWithSpinner },

    props: {
      players: {
        type: Array as PropType<FunIceBreakerPlayer[]>,
        default: () => []
      },
      avatars: {
        type: Object as PropType<AvatarObject>,
        default: () => ({})
      },
      joinLoading: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const name = ref('');
      const nickname = ref('');
      const job = ref('');
      const selectedAvatar: Ref<null | Avatar> = ref(null);

      const availableAvatars = computed(() => {
        const takenAvatars = props.players.map((p: FunIceBreakerPlayer) => p.avatar.name);

        return props.avatars.list.filter((avatar: string) => !takenAvatars.includes(avatar));
      });

      const nameTaken = computed(() => {
        const names = props.players.map((p: FunIceBreakerPlayer) => p.name);

        return names.includes(name.value);
      });

      const someInputTooLong = computed(() => {
        return [name.value, nickname.value, job.value].some(v => v.length > 20);
      });

      const selectedAvatarExistsInAvatars = computed(() => {
        if (!selectedAvatar.value) {
          return false;
        }

        return availableAvatars.value.some(avatar => avatar === selectedAvatar.value?.name);
      });

      return {
        availableAvatars,
        nameTaken,
        name,
        nickname,
        job,
        selectedAvatar,
        selectedAvatarExistsInAvatars,
        someInputTooLong
      };
    }
  });
